import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(https://api.fzacademia.com/static/images/landing/map.jpg)',
    color: '#FFFFFF',
    maxWidth: '100%',
    width: '100vw',
    height: '100vh',
    display: 'grid',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    gridTemplateColumns: '1fr 1fr',
  },
  container: {
    gridColumnStart: '2',
    gap: '10px !important',
    padding: '0 50px',
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
  title: {
    fontWeight: 'bolder',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    fontSize: 'calc(5em + 1vw)',
    lineHeight: '1em',
    marginBottom: '15px',
    [theme.breakpoints.only('xs')]: {
      fontSize: 'calc(3em + 1vw)',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 'calc(2.8em + 1vw)',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 'calc(5em + 1vw)',
    },
  },
  subtitle: {
    fontWeight: 'bolder',
    textAlign: 'right',
    fontSize: 'calc(1em + 1vw)',
    lineHeight: 'calc(.7em + 1vw)',
    margin: '0',
    [theme.breakpoints.down('lg')]: {
      lineHeight: 'calc(1em + 1vw)',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 'calc(.8em + 1vw)',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: 'calc(.7em + 1vw)',
    },
  },
}))

export const AnywhereSection = () => {
  const classes = useStyles()
  return (
    <div className={classes.image}>
      <div className={classes.container}>
        <div className={classes.title}>
          <span>APRENDE</span>
          <span>DESDE</span>
          <span>CUALQUIER</span>
          <span>PARTE</span>
        </div>
        <p className={classes.subtitle}>Contamos con estudiantes en 17 países, que hoy aprenden con FZ academia.</p>
      </div>
    </div>
  )
}
