import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFFFFF',
    borderRadius: 20,
    maxWidth: 350,
    boxShadow: '0 5px 10px 5px rgba(0, 0, 0, .2)',
    position: 'relative',
  },
  player: {
    '& video': {
      overflow: 'hidden',
      borderRadius: '20px 20px 0 0',
      width: '100%',
    },
  },
  pauseIcon: {
    position: 'absolute',
    display: 'flex',
    bottom: -20,
    left: 15,

    '&::after, &::before': {
      content: '""',
      width: 8,
      height: 40,
      margin: '0 2px',
      backgroundColor: '#B7B7B7',
    },
  },

  message: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      '& .title': {
        fontSize: 'calc(.7em + 1vw) !important',
      },
      '& .subtitle': {
        fontSize: 'calc(.5em + 1vw) !important',
      },
    },

    '& .title': {
      fontSize: 'calc(.4em + 1vw)',
      marginBottom: 5,
      fontStyle: 'italic',
      fontWeight: 'bold',
    },

    '& .subtitle': {
      marginBottom: 5,
      fontSize: 'calc(.2em + 1vw)',
      fontWeight: 400,
      fontStyle: 'italic',
    },

  },

}))

export const ContactVideo = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.player}>
        <video
          data-setup="{}"
          id="landing-video"
          className="video-js vjs-default-skin vjs-big-play-centered"
          controls
          preload="auto"
          poster="https://api.fzacademia.com/static/images/landing/CEO.jpg"
        >
          <track
            default
            kind="captions"
            srcLang="es"
          />
          <source
            src="https://fzacademia-vod-599277488377-us-east-2.s3.us-east-2.amazonaws.com/input/escuela-de-musica-online-freddy-zuluaga.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className={classes.pauseIcon} />
      <div className={classes.message}>
        <span className="title">Aprende con FZ academia</span>
        <span className="subtitle">Mensaje de nuestro CEO</span>
      </div>
    </div>
  )
}
