import { makeStyles } from '@mui/styles'
import { ContactVideo } from './ContactVideo'
import { ContactForm } from './ContactForm'

const useStyles = makeStyles((theme) => ({
  contact: {
    backgroundImage: 'url(https://api.fzacademia.com/static/images/landing/guitarra.jpg)',
    boxSizing: 'border-box',
    maxWidth: '100%',
    width: '200vw',
    height: '200vh',
    backgroundSize: 'cover',
    backgroundRepeat: ' no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    alignItems: 'center',
    paddingLeft: 50,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 10,
    },
  },
}))

export const ContactSection = () => {
  const classes = useStyles()

  return (
    <div id="contact" className={classes.contact}>
      <ContactVideo />
      <ContactForm />
    </div>
  )
}
